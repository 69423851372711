<template>
  <footer class="footer">
    <nav class="nav">
      <!-- may be redirect or similar -->
      <div class="nav--section">
        <!-- <router-link class="nav--section__item button--bubble" to="/">Home</router-link> -->
       <!-- <a href='http://uncommonsessions.space' target="_blank" class="nav--section__item button--bubble">Home</a>-->
      </div>
      <!-- player here -->
      <div class="nav--section users">
        <div id='noPointerEvent' class="nav--section__item no-bg button--bubble">
          <span id='userCount'>{{ this.userCount }}</span>&nbsp;active peers
        </div>
      </div>
      <!-- info btn -->
      <div class="nav--section">
        <router-link  class="nav--section__item button--bubble home" to="/dashboard">Home</router-link>
      </div>
    </nav>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  props: {
    mobile: Boolean,
    userCount: Number,
  },
  mounted() {
    // console.log("mount footer");
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

footer.footer {
  position: fixed;
  // .5rem should be global css var
  width: calc(100% - 2rem);
  bottom: 0;
  margin: 0 0 1rem 1rem;
  left: 0;
  z-index: 1;
  // --- same as nav (should be in global/specific stylesheet to voice) ---
  & .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      &__item {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.nav--section__item a#sessionsLink {
  pointer-events: none !important;
}

.nav--section__item span#userCount {
  color: red;
}
</style>