class Namespace {
  constructor(nsp) {
    this.nsp = nsp
  }

  formatNsp() {
    return this.nsp.replace('/spaces/', '')
  }

  slugifyNsp() {
    return this.formatNsp(this.nsp).toLowerCase().replace(/[^a-z0-9]/g, '-')
  }
}

export default Namespace