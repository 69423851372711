<template>
  <div id="app">
    <router-view :key='returnKey' class="view" />
  </div>
</template>

<script>
import ress from "ress";
import regex from "../utils/regex.js";
import Namespace from "../utils/namespace.js";

export default {
  name: "App",
  computed: {
    returnKey() {
      return this.$route.name === 'Space' ? new Namespace(this.$route.params.id).slugifyNsp() : this.$route.fullPath
    }
  }
};
</script>

<style lang="scss">
@import "./styles/_global.scss";
@import "./styles/_fonts.scss";

html,
body {
  width: 100%;
  height: 100%;
  // font-size: 16px;
  font-size: 16px;
  line-height: 1.2rem;
  font-family: UncommonRegular, sans-serif;
  overflow: hidden;
  position: fixed;
}

#app {
  width: 100%;
  height: 100%;
}

.view {
  background: #ccc;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  &.dashboard {
    background: #ccc;
  }
  &.space {
    background: #ffcbaf;
  }
}
</style>