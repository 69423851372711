import types from './types'

const actions = {
  setMobile: (context) => {
    context.commit(types.SET_MOBILE)
  },
  setAudioBlobPlaying: (context, blob) => {
    context.commit('SET_AUDIO_BLOB_PLAYING', blob)
  },
  unsetAudioBlobPlaying: (context) => {
    context.commit('UNSET_AUDIO_BLOB_PLAYING')
  },
  // audio playback refers to playing specific blob
  setAudioPlayback: (context) => {
    context.commit('SET_AUDIO_PLAYBACK')
  },
  unsetAudioPlayback: (context) => {
    context.commit('UNSET_AUDIO_PLAYBACK')
  },
  // full audio playback refers to playing through all blobs sequentially
  setFullAudioPlayback: (context) => {
    context.commit('SET_FULL_AUDIO_PLAYBACK')
  },
  unsetFullAudioPlayback: (context) => {
    context.commit('UNSET_FULL_AUDIO_PLAYBACK')
  },
  setCompose: (context) => {
    context.commit('SET_COMPOSE')
  },
  setComposeFilter: (context, str) => {
    context.commit('SET_COMPOSE_FILTER', str)
  },
  unsetCompose: (context) => {
    context.commit('UNSET_COMPOSE')
  },
  enableRecording: (context) => {
    context.commit('ENABLE_RECORDING')
  },
  disableRecording: (context) => {
    context.commit('DISABLE_RECORDING')
  },
  setVolume: (context, n) => {
    context.commit('SET_VOLUME', n)
  },
  openInviteModal: (context) => {
    context.commit('OPEN_INVITE_MODAL')
  },
  closeInviteModal: (context) => {
    context.commit('CLOSE_INVITE_MODAL')
  },
  openInfoModal: (context) => {
    context.commit('OPEN_INFO_MODAL')
  },
  closeInfoModal: (context) => {
    context.commit('CLOSE_INFO_MODAL')
  }
}

export default actions