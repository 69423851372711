import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSocketIO from 'vue-socket.io'
import store from '../store'
import Home from '../views/Home.vue'
import Space from '../views/Space.vue'
import Dashboard from '../views/Dashboard.vue'
import Namespace from "../../utils/namespace.js";
require('dotenv').config()

console.log('namespace?', Namespace)

Vue.use(VueRouter)
const backend = process.env.NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_BACKEND : process.env.VUE_APP_DEVELOPMENT_BACKEND

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter (to, from, next) {
      Vue.use(new VueSocketIO({
        // turned off for now
        debug: false,
        // todo: add .env variables for this
        connection: backend + '/dashboard',
        vuex: {
          store,
          actionPrefix: 'SOCKET_',
          mutationPrefix: 'SOCKET_'
        },
        options: {
          useConnectionNamespace: true,
          namespaceName: '/dashboard'
        },
        extraHeaders: {
          'Access-Control-Allow-Credentials': true
        }
      }))
      next()
    }
  }, 
  {
    path: '/spaces/:id',
    name: 'Space',
    component: Space,
    props: true,
    beforeEnter (to, from, next) {
      console.log('to?', to)
      const newRoomSlug = new Namespace(to.params.id).slugifyNsp()
      Vue.use(new VueSocketIO({
        // turned off for now
        debug: false,
        // todo: add .env variables for this
        connection: backend + '/spaces/' + newRoomSlug,
        vuex: {
          store,
          actionPrefix: 'SOCKET_',
          mutationPrefix: 'SOCKET_'
        },
        options: {
          useConnectionNamespace: true,
          namespaceName: '/spaces/' + newRoomSlug,
        },
        extraHeaders: {
          'Access-Control-Allow-Credentials': true
        }
      }))
      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})


export default router