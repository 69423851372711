const mutations = {
  SET_MOBILE: (state) => {
    state.mobile = true
  },
  SET_AUDIO_BLOB_PLAYING: (state, blob) => {
    state.audio_blob_playing = blob
  },
  UNSET_AUDIO_BLOB_PLAYING: (state) => {
    state.audio_blob_playing = null 
  },
  SET_AUDIO_PLAYBACK: (state) => {
    state.audio_playback = true 
  },
  UNSET_AUDIO_PLAYBACK: (state) => {
    state.audio_playback = false 
  },
  SET_FULL_AUDIO_PLAYBACK: (state) => {
    state.full_audio_playback = true
  },
  UNSET_FULL_AUDIO_PLAYBACK: (state) => {
    state.full_audio_playback = false 
  },
  SET_COMPOSE: (state) => {
    state.compose = true 
  },
  UNSET_COMPOSE: (state) => {
    state.compose = false
  },
  SET_COMPOSE_FILTER: (state, str) => {
    state.compose_filter = str
  },
  ENABLE_RECORDING: (state) => {
    state.recording_enabled = true 
  },
  DISABLE_RECORDING: (state) => {
    state.recording_enabled = false
  },
  SET_VOLUME: (state, n) => {
    state.volume = n
  },
  OPEN_INVITE_MODAL: (state) => {
    state.invite_modal_open = true
  },
  CLOSE_INVITE_MODAL: (state) => {
    state.invite_modal_open = false 
  },
  OPEN_INFO_MODAL: (state) => {
    state.info_modal_open = true
  },
  CLOSE_INFO_MODAL: (state) => {
    state.info_modal_open = false 
  },
  // ...
  // ...
  // mutations need to be like this
  SOCKET_connect: (state, status)  => {
    // console.log('socket connected vuex', state, status)
    // state.connect = true;
  },
  SOCKET_register_connection: (state, status) => {
    // console.log('handle connection globally', state, status)
    // state.connect = true;
  },
  // SOCKET_handle_connection: (state, status) => {
  //   console.log('handle connection globally', state, status)
  //   // state.connect = true;
  // },
}

export default mutations