<template>
  <div v-if='this.condition' class='message'>
   <!-- {{ this.content }}-->
  </div>
</template>

<script>

export default {
  name: "Message",
  props: {
    content: String,
    condition: Boolean 
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.message {
  top: 0;
  position: absolute;
  background: red;
  color: white;
  display: inline-flex;
  padding: 1rem;
  opacity: 0;
  width: auto;
  border-radius: 2rem 2rem;
  margin: 6.5rem 0 0 0.5rem;
}

</style>