const types = {
  SET_MOBILE: 'SET_MOBILE',
  SET_AUDIO_BLOB_PLAYING: 'SET_AUDIO_BLOB_PLAYING',
  UNSET_AUDIO_BLOB_PLAYING: 'UNSET_AUDIO_BLOB_PLAYING',
  SET_AUDIO_PLAYBACK: 'SET_AUDIO_PLAYBACK',
  UNSET_AUDIO_PLAYBACK: 'UNSET_AUDIO_PLAYBACK',
  SET_FULL_AUDIO_PLAYBACK: 'SET_FULL AUDIO_PLAYBACK',
  UNSET_FULL_AUDIO_PLAYBACK: 'UNSET_FULL_AUDIO_PLAYBACK',
  SET_COMPOSE: 'SET_COMPOSE',
  UNSET_COMPOSE: 'UNSET_COMPOSE',
  SET_COMPOSE_FILTER: 'SET_COMPOSE_FILTER',
  SET_VOLUME: 'SET_VOLUME',
  OPEN_INVITE_MODAL: 'OPEN_INVITE_MODAL',
  CLOSE_INVITE_MODAL: 'CLOSE_INVITE_MODAL',
  OPEN_INFO_MODAL: 'OPEN_INFO_MODAL',
  CLOSE_INFO_MODAL: 'CLOSE_INFO_MODAL'
}

export default types