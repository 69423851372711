<template>
  <div :style='returnStyle' class='placeholder'></div>
</template>

<script>

// placeholder needs to be designed/styled
// maybe use 3 dots (...) or some spinning thing
export default {
  name: "Placeholder",
  props: {
    x: Number, 
    y: Number
  },
  computed: {
    returnStyle() {
    	return 'top:' + ' ' + this.y + 'px;' + ' ' + 'left:' + ' ' + this.x + 'px;'
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.placeholder {
  cursor: default !important;
  background: red;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50% 50%;
  z-index: 101;
  pointer-events: none;
	animation: blink .5s infinite;
}

@keyframes blink {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

</style>