<template>
  <div :style='returnStyle' class='cursor'></div>
</template>

<script>

export default {
  name: "UserCursor",
  props: {
    mobile: Boolean,
    id: String,
    users: Array,
    x: Number, 
    y: Number,
    socket: Object
  },
  mounted() {
    // console.log('mounted cursor', this.socket)
  },
  computed: {
    returnStyle() {
      // this seems to work, but would be better not to render the component in the first place
      // i.e. to mutate the array of users in Space component + fitler there
      if (this.socket.id === this.id) {
        return 'opacity: 0; pointer-events: none;'
      }
      else {
        return 'top:' + ' ' + this.y + 'px;' + ' ' + 'left:' + ' ' + this.x + 'px;'
      }
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.cursor {
  cursor: default !important;
  background: red;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 50% 50%;
  width: 30px;
  height: 30px;
  border-radius: 50% 50%;
  z-index: 101;
  pointer-events: none;
}

</style>