<template>
  <div class="modal invite">

    <div>{{ this.richText }}</div>
    <button @click='handleCopyClick'>Copy URL</button>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "InviteModal",
  data() {
    return {
      open: false
    }
  },
  props: {
    richText: String 
  },
  computed: {
    ...mapState(['invite_modal_open', 'info_modal_open']),
		spaceUrl() {
			// w.e. parsing is needed here
			return window.location
		}
	},
  methods: {
    ...mapActions(['openInfoModal', 'closeInfoModal', 'closeInviteModal', 'openInviteModal']),
    handleCloseClick() {
      this.closeInviteModal()
    },
    async handleCopyClick() {
			try {
				await navigator.clipboard.writeText(this.spaceUrl);
				alert('URL has been copied to your clipboard!');
			} catch($e) {
				alert('Cannot copy, copy the URL directly instead');
			}
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.modal {
  background: white; 
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
  max-height: 300px;
  position: fixed;
  max-width: 500px;
	min-width: 350px;
  z-index: 101;
	display: flex;
	flex-flow: column;
	padding: 2rem;
	overflow-y: scroll;
}

.close {
	text-align: right;
	width: fit-content;
	color: #fff;
	border-radius: 3rem;
	padding: 0.2rem 0.2rem;
	padding: 0.2rem 0.2rem;
	background-color: red;
}

.modal button {
	border-radius: 3rem;
	padding: 0.2rem 0.2rem;
	background-color: red;
	color: #fff;
	margin-bottom: 0;
}

.modal div {
	margin-bottom: 1rem;
	& span {
		font-size: .65rem;
	}
}


</style>