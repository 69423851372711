<template>
  <div :class='returnAudioWrapperClass' :style='returnStyle(this.data)' ref='audioWrapper' @mousedown='handleMouseDown' @mouseup='handleMouseUp' @mousemove='handleMouseMove'>
    <audio ref='audio' :src='this.returnAudioSrc' controls></audio>
    <div class='audio--wrapper--controls' @click='handleClick'>
      <div class='audio--wrapper--controls__btn'>{{ this.playing ? 'PAUSE' : 'PLAY' }}</div>
      <div class='audio--wrapper--controls__user'>
        <div class="audio--wrapper--controls__user-label">User</div>
        <div class="audio--wrapper--controls__user-sig">{{ this.userSignature }}</div>
      </div>
      <div class='audio--wrapper--controls__timestamp'>
        <div class="audio--wrapper--controls__timestamp-label">Time</div>
        <div class="audio--wrapper--controls__timestamp-time">{{ this.returnAudioTimestamp }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from "moment";
import { draggable } from '../../../utils/draggable'
import _ from 'lodash'

export default {
  name: "Audio",
  props: {
    mobile: Boolean,
    data: Object,
    socket: Object,
    signature: String, 
    id: String 
  },
  data() {
    return {
      playing: false,
      // -- may be useful to break this into object --- 
      initX: null,
      initY: null,
      firstX: null,
      firstY: null,
      // ...
      dragging: false,
      pos: {
        x: null, 
        y: null 
      },
      mousedown: false, 
      updatingFromServer: false
    }
  },
  mounted() {
    const self = this 
    this.pos.x = this.data.pageX
    this.pos.y = this.data.pageY

    this.$refs.audio.onplay = () => {
      self.playing = true
      // may need $nextTick here
      if (!self.audio_playback) {
        self.setAudioPlayback()
      }
    }
    
    // this event fires oneneded + on pause
    this.$refs.audio.onpause = () => {
      self.playing = false
      if (self.audio_playback) {
        self.unsetAudioPlayback()
      }
    }
    
    // no longer using this listener as the onpause fires when audio ends
    // this.$refs.audio.onended = () => {
      // console.log('handle audio end')
      // self.handleAudioEnd()
    // } 
  },
  watch: {
    full_audio_playback(newVal, oldVal) {
      if (!newVal) {
        this.pauseAudio()
      }
    },
    audio_playback(newVal, oldVal) {
      if (newVal) {
        if (this.audio_blob_playing === this.id) {
          this.playAudio()
        }
      }
      else {
        this.pauseAudio()
      }
    },
    volume(newVal, oldVal) {
      this.$refs.audio.volume = newVal
    },
    data: {
      deep: true,
      handler(newVal, oldVal) {
        const self = this 
        // checking this.dragging is not enough here as user could also be dragging another el/the same el
        // will need to check against socket.id or something
        if (newVal.pageX !== oldVal.pageX && !this.dragging || newVal.pageY !== oldVal.pageY && !this.dragging) {
            this.pos.x = newVal.pageX
            this.pos.y = newVal.pageY
        }
      }
    },
    pos: {
      deep: true, 
      handler(newVal, oldVal) {
        // id is passed to server here so it when it is returned from socket 
        // the specific element can be identified in the dom (as it is also passed in classname)
        // and moved accordingly for all sockets
        // ...
        // checking this.dragging is not enough here as user could also be dragging another el/the same el
        // will need to check against socket.id or something
        if (this.dragging) {
          this.socket.emit('register_recording_pos_update', { id: this.id, x: this.pos.x, y: this.pos.y })
        }
      }
    }
  },
  computed: {
    ...mapState(['audio_blob_playing', 'audio_playback', 'full_audio_playback', 'compose', 'volume']),
    // ...
    returnAudioSrc: function() {
      let blob = new Blob([this.data.audio], {
        type: "audio/mpeg; codecs=opus",
      });
      let audioUrl = window.URL.createObjectURL(blob);
      return audioUrl 
    },
    returnAudioTimestamp: function() {
      return moment(this.data.time).format("hh:mm:ssA");
    },
    returnAudioWrapperClass: function() {
      const playingClass = this.playing ? 'playing' : 'paused'
      if (this.compose) {
        return 'audio--wrapper composed' + ' ' + this.id + ' ' + playingClass
      }
      else {
        return this.dragging ? 
          'audio--wrapper uncomposed' + ' ' + this.id + ' ' + 'dragging' + ' ' + playingClass 
          :
          'audio--wrapper uncomposed' + ' ' + this.id + ' ' + playingClass 
      }
    },
    userSignature() {
      return this.signature
    }
    // this also needs to get cached somehow...
    // userSignature() {
    //   return '$'
    // }
  },
  methods: {
    // map actions of global state
    ...mapActions(['setAudioBlobPlaying', 'unsetAudioBlobPlaying', 'setAudioPlayback', 'unsetAudioPlayback', 'setFullAudioPlayback', 'unsetFullAudioPlayback']),
    // methods local to component
    returnStyle(data) {
      // need to do some translation of x/y values to make them relative to all screens here
      if (this.compose) {
        return false;
      }
      else {
        return (
          "top:" + " " + this.pos.y + "px;" + "left:" + " " + this.pos.x + "px;"
        );
      }
    },
    returnBlob(data) {
      let blob = new Blob([this.data.audio], {
        type: "audio/mpeg; codecs=opus",
      });
      let audioUrl = window.URL.createObjectURL(blob);
      let currentTime = moment(this.data.time).format("MM/DD/YYYY hh:mm a");
      return `<div class='audio--wrapper'><audio src=${audioUrl} controls></audio><div><span>${currentTime}</span><span>&nbsp;</span><span>${this.data.duration} Seconds</span></div></div>`;
    },
    handleDrag(event) {
      if (!this.compose) {
        // need to pass info of element here
        this.dragging = true
        // update local state 
        this.pos.x = this.initX + event.pageX - this.firstX
        this.pos.y = this.initY + event.pageY - this.firstY
      }
    },
    handleMouseDown(event) {
      event.preventDefault()
      const audioWrapper = this.$refs.audioWrapper
      // save these locally
      // may also be easier to pass these as args to this.handleDrag
      this.initX = this.pos.x  
      this.initY = this.pos.y
      this.firstX = event.pageX
      this.firstY = event.pageY
      this.mousedown = true
    },
    handleMouseUp(event) {
      const self = this 
      setTimeout(() => {
        if (self.mousedown) {
          self.mousedown = false
        }
        if (self.dragging) {
          self.dragging = false 
        }
      }, 100)
    },
    // keep eye on this -- may actually be better to add/remove evt listener
    // handleMouseMove(event) {
    //   if (this.mousedown) {
    //     this.handleDrag(event)
    //   }
    // },
    // throttle is necessary to prevent to many fn calls, which eventually breaks localbase sync stuff
    // instead it may be better to wait for the end event of drag and then animate the <audio> el into its new x + y pos
    handleMouseMove: _.throttle(
      function(event) {
        if (this.mousedown) {
          this.handleDrag(event)
        }
    // turned down for now
    }, 1),
    pauseAudio() {
      this.$refs.audio.pause()
      this.playing = false
    },
    playAudio() {
      this.$refs.audio.play()
      this.playing = true
    },
    handleClick() {
      if (!this.dragging) {
        const self = this 
        if (this.audio_blob_playing !== this.id) {
          this.setAudioBlobPlaying(this.id)
        }
        if (this.playing) {
          this.pauseAudio()
          if (this.full_audio_playback) {
            this.unsetFullAudioPlayback()
          }
        }
        else {
          this.playAudio()
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.audio--wrapper {
  background: white;
  font-size: 1.4rem;
  padding: .5rem 1rem .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem 5rem;
  &.playing {
    background: red;
    color: white;
  }
  &.composed {
    position: relative;
    margin: 0 1rem 1rem 0;
    align-self: center;
    width: 75vw;
    max-width: 600px;
    z-index: 3;
    // added for extra scroll space
    &:last-child {
      margin-bottom: 8rem;
    }
  }
  &.uncomposed {
    z-index: 100;
    position: absolute;
  }
  &.dragging {
    cursor: move;
    .audio--wrapper--controls {
      pointer-events: none !important;
    }
  }
  & audio {
    display: none;
    // temp!
    pointer-events: none;
  }
  &--controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.1rem 0.5rem;
    transition: all .25s ease;
    &__btn {
      // margin-right: 1rem;
      margin: 0 2rem 0 0;
      padding-top: .5rem;
      font-size: 1.4rem;
    }
    &__user {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 2rem;
      &-label {
        font-size: 50%;
      }
      &-sig {
        text-align: center;
      }
    }
    &__timestamp {
      text-transform: lowercase;
      &-label {
        font-size: 50%;
      }
    }
  }
}
</style>