<template>
  <header class="header">
    <nav class="nav">
      <!-- may be redirect or similar -->
      <div class="nav--section">
        <div class="invite-btn" :class='inviteClass' @click='handleInviteClick'>Invite</div>
        <div :class='composeClass' @click='handleComposeClick'>Compose</div>
        <div class="nav--section__item">
          <Player :recordings='this.recordings' />
        </div>
      </div>

      <div class = "nav--section title-is">
        <div class="nav--section__item">
          <div class='namespace--title'>{{ namespace }}</div>
        </div>
      </div>

      <!-- player here -->
      <!-- info btn -->
    </nav>

  </header>
</template>

<script>
import Player from "./Player";
import { mapState, mapActions } from 'vuex'

export default {
  name: "Header",
  props: {
    mobile: Boolean,
    recordings: Array,
    namespace: String,
    socket: Object
  },
  components: {
    Player,
  },
  mounted() {
    // console.log("mount header");
  },
  computed: {
    ...mapState([
      'compose', 
      'recording_enabled', 
      'invite_modal_open', 
      'info_modal_open'
    ]),
    inviteClass() {
      return this.invite_modal_open ? 'nav--section__item button--bubble invite-btn active' : 'nav--section__item button--bubble'
    },
    recordClass() {
      return this.recording_enabled ? 'nav--section__item button--bubble active' : 'nav--section__item button--bubble'
    },
    composeClass() {
      return this.compose ? 'nav--section__item button--bubble active' : 'nav--section__item button--bubble'
    },
    infoClass() {
      return this.info_modal_open ? 'nav--section__item button--bubble active' : 'nav--section__item button--bubble'
    },
  },
  methods: {
    ...mapActions([
      'setCompose', 
      'unsetCompose', 
      'enableRecording',
      'disableRecording', 
      'closeInviteModal', 
      'openInviteModal', 
      'openInfoModal', 
      'closeInfoModal'
    ]),
    handleComposeClick() {
      !this.compose ? this.socket.emit('register_set_compose') : this.socket.emit('register_unset_compose')
      // if (!this.compose) {
      //   if (this.recording_enabled) {
      //     this.disableRecording()
      //   }
      //   this.socket.emit('register_set_compose')
      // }
      // else {
      //   this.socket.emit('register_unset_compose')
      // }
    },
    handleRecordClick() {
      this.recording_enabled ? this.disableRecording() : !this.compose ? this.enableRecording() : false
    },
    handleInviteClick() {
      !this.invite_modal_open ? this.openInviteModal() : this.closeInviteModal()
    },
    handleInfoClick() {
      !this.info_modal_open ? this.openInfoModal() : this.closeInfoModal()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

header.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  & .nav {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      &:nth-child(2) {
        flex-grow: 1;
        margin: 0 .5rem 0 .5rem;
        justify-content: center;
        position: relative;
        .nav--section__item {
          flex-grow: 1;
        }
      }
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
      &:last-child {
        .nav--section__item {
          margin-right: unset;
        }
      }
    }
  }
}

.namespace--title {
  text-align: center;
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
}

</style>