const glyphs = [
  '#',
  '$',
  ':',
  '=',
  '&',
  ')',
  '}',
  '+'
]

const assignPersonalGlyph = (x) => {
  return glyphs[x]
}

export default function(uniqStr) {
  // can split string or w.e. here
  // select from glyphs...
  // return assignPersonalGlyph(uniqStr)
  return 'test'
}

// export default returnSignature