<template>
  <div class="player--wrapper">
    <div class = 'player--wrapper__play' @click='handlePlayClick'>
      <img :src='returnImgSrc' />
    </div>
    <div class = 'player--wrapper__volume'>
      <input v-model="volumeValue" type="range" min="1" max="100" class="slider" id="volumeSlider">
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "Player",
  props: {
    mobile: Boolean,
    recordings: Array
  },
  data() {
    return {
      playing: false,
      audioCount: 0,
      volumeValue: 50
    }
  },
  watch: {
    volumeValue(newVal, oldVal) {
      this.setVolume((newVal / 100).toFixed(1))
    },
    audio_playback(newVal, oldVal) {
      // if one audio el has finished playing back
      if (!newVal) {
        // if full audio is playing back
        if (this.full_audio_playback) {
          this.audioCount += 1
          // if there is another recording left, play 
          if (this.audioCount <= (this.recordings.length - 1)) {
            this.playNextAudio()
          }
          else {
            // reset
            this.audioCount = 0
            this.unsetFullAudioPlayback()
          }
        }
        // if audio play back has been stopped (paused)
        else {
          this.unsetFullAudioPlayback()
        }
      }
    }
  },
  computed: {
    ...mapState(['audio_blob_playing', 'audio_playback', 'full_audio_playback', 'volume']),
    // ...organize recordings by y value here...
    // organizedRecordings: function() {
    //   return this.recordings 
    // },
    returnImgSrc: function() {
      return this.full_audio_playback ? '/pause.svg' : '/play.svg'
    },
  },
  methods: {
    ...mapActions(['setAudioBlobPlaying', 'unsetAudioBlobPlaying', 'setAudioPlayback', 'unsetAudioPlayback', 'setFullAudioPlayback', 'unsetFullAudioPlayback', 'setVolume']),
    playNextAudio: function() {
      // find index of current audio blob 
      const self = this 
      if (this.full_audio_playback) {
        let newBlob = this.recordings[this.audioCount].id
        this.setAudioBlobPlaying(newBlob)
        // some kind of break is needed - hence timeout
        setTimeout(() => {
          self.setAudioPlayback()
        }, 1000)
      }
    },
    handlePlayClick: function() {
      if (this.recordings.length) {
        if (!this.full_audio_playback) {
          console.log('play through audio now')
          this.setFullAudioPlayback()
          this.playNextAudio()
        }
        else {
          this.unsetFullAudioPlayback()
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.player--wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  &__play {
    padding: 0 1rem 0 1rem;
    margin-right: 0rem;
    height: 100%;
    width: 100%;
    flex: 0 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & img {
      height: 2rem !important;
      max-height: 100% !important;
    }
  }
  &__volume {
    flex: 1 0;
    // border: solid black 1px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  min-width: 200px;
  height: 10px;
  border-radius: 2rem;  
  background: red;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%; 
  background: red;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: red;
  cursor: pointer;
}
</style>