// an attempt to systemize the events sent between the server/client/socket connections
// if vuex is used, these types should simply be used there

// does not really work on component level in the client
// as the socket listener cannot take values of object (only a string)
const types = {
  HANDLE_MAX_NSP_ERROR: 'handle_max_nsp_error',
  HANDLE_MAX_SERVER_ERROR: 'handle_max_server_error',
  HANDLE_CONNECTION_EVENT: 'handle_connection_event',
  HANDLE_PUBLIC_SETTING: 'handle_public_setting',
  HANDLE_POST_RECORDING_EVENT: 'handle_post_recording_event',
  HANDLE_UPDATE_RECORDING_POS: 'handle_update_recording_pos',
  HANDLE_SET_COMPOSE: 'handle_set_compose',
  HANDLE_UNSET_COMPOSE: 'handle_unset_compose',
  HANDLE_COMPOSE_FILTER: 'handle_compose_filter',
  HANDLE_CURSOR_MOVE: 'handle_cursor_move',
  HANDLE_DISCONNECTION_EVENT: 'handle_disconnection_event',
  HANDLE_RECORDING_START: 'handle_recording_start',
  HANDLE_RECORDING_END: 'handle_recording_end'
}

module.exports = types