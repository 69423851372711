<template>
  <div class="modal info">
<p>Voice</p>
    <p>
Only one minute clips can be recorded by double clicking anywhere in the screen or the Record Button on the left top corner. 
</p>
<p>
A blinking cursor indicates a recording taking place. Multiple recordings can be happening at the same time.
</p>
<p>
Sound clips can be dragged anywhere on the screen.
</p>
<p>
By clicking the Compose button all clips get automatically organized chronologically by the time they were recorded, creating an unexpected composition. 
</p>
<p>
Press Play button to linearly play them all together.
</p>
<p>
Participants are visible to each other by their computer cursor. 
</p>
<p>
A session is only active when at least one participant is present in it but the outcome only makes sense when multiple participants interact. 
</p>
<p>
 
</p>
<p>
No content or data is recorded or archived. Each session is a moment in time, an immediate temporary space. An ephemeral decentralized library.
</p>
<p>
The sessions will be saved only in the cache of the computer in use. If the cache is cleaned, the content of that session will no longer be accessible from that computer.
</p>
<p>
The only way to permanently save the results of the session is by using an external sound recording app (such as: <a href="https://existential.audio/blackhole/">BlackHole</a>, <a href="https://rogueamoeba.com/piezo/">Piezo</a>, <a href="https://soundflower.softonic.nl/mac">Sunflower</a>)
</p>
<p>
The content between participants is being shared by using a temporary memory enabled by <a href="https://en.wikipedia.org/wiki/WebSocket">WebSockets</a>. WebSocket is a computer communications protocol, providing full-duplex communication channels over a single connection.
</p>
<p>
<a href="https://uncommonsessions.space/">Read more</a>
</p>
		<!-- todo: more info specific to how to use tool here -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "InfoModal",
  data() {
    return {
      open: false
    }
  },
  props: {
    richText: String 
  },
  computed: {
    ...mapState(['info_modal_open']),
  },
  methods: {
    ...mapActions(['openInfoModal', 'closeInfoModal']),
    handleCloseClick() {
      this.closeInfoModal()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.modal {
  background: white; 
	top: 50%;
	right: 0;
  width: 50vw;
	height: 100vh;
  transform: translateY(-50%);
  position: fixed;
  z-index: 101;
	display: flex;
	flex-flow: column;
	padding: 5rem 1rem;
	overflow-y: scroll;
}

.modal p {
  margin-bottom: 1rem;
	width: 100%;
}

.modal button {
	border: solid black 1px;
	margin-bottom: 1rem;
}

.modal div {
	margin-bottom: 1rem;
	& span {
		font-size: .65rem;
	}
}


</style>