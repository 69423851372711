<template>
  <div class = "home">
    <!-- may be redirect or similar -->
    <!-- <router-link to='/dashboard'>Go to dashboard</router-link> -->
  </div>
</template>

<script>


export default {
  name: 'Home',
  props: {
    mobile: Boolean,
  },
  mounted() {
    this.$router.push('/dashboard')
  }
}
</script>

<style>

</style>