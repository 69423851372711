<template>
  <div class="dashboard">

    <div class = 'dashboard--inner'>
      <DashboardForm />

      <div class = 'rooms--wrapper' v-if='this.publicSpaces.length'>
        <div class = 'rooms--wrapper__title'>Active voice sessions</div>
        <ul>
          <li :key="space.name" v-for="space in publicSpaces">
            <router-link :to="space.name"> {{ formatNamespace(space.name) }} </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class = 'intro--wrapper'>
      <p>
                By assigning a name to the session a new url will be automatically created.
Participants with this url can access the session directly.

<a class="link-intro" href="https://uncommonsessions.space/">Read more</a>

            </p>
    </div>

  </div>
</template>

<script>
import Namespace from "../../utils/namespace.js"
import DashboardForm from "../components/dashboard/DashboardForm"
import Localbase from 'localbase'

// ...
let db = new Localbase('db')
db.config.debug = false

export default {
  name: "Dashboard",
  // will have data passed from socket store here
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      spaces: [],
      localData: []
    };
  },
  components: {
    DashboardForm
  },
  watch: {
    spaces(newVal, oldVal) {
      console.log('spaces updated', newVal, oldVal)
      const self = this      
      this.$nextTick(() => {
        if (newVal !== oldVal) {
          self.$forceUpdate()
        }
        if (db.collection('spaces') !== undefined) {
          self.spaces.forEach((space, key) => {
            // todo: will need to empty spaces from localdb too...
            db.collection('spaces').get().then(data => {
              // db.collection('spaces').add(space)
              if (data.length) {
                // if already exists...
                if (!data.some(x => x.name === space.name)) {
                  db.collection('spaces').add(space)
                }
              }
              else {
                db.collection('spaces').add(space)
              }
            })
          })
        }
      })
    },
  },
	computed: {
    publicSpaces() {
			return this.spaces.filter((space, key) => {
    		return space.public 
  		})
    },
	},
  sockets: {
    connect() {
      this.$socket.emit("register_connection");
    },
    disconnect() {
      // console.log("disconnect dashboard");
    },
    handle_connection_event(data) {
      // this.spaces = data
      // ...
      const self = this;
      // emtpy local state first
      this.spaces = []

      // throw to bottom of stack (was setTimeout)
      this.$nextTick(() => {

        // currently not working: the idea is to update local state of spaces
        // to reflect what was saved in local storage (so we know if session is private or public) 

        // store localData in an array
        let localData = []
        db.collection('spaces').get().then(data => {
          if (data.length) {
            localData = data 
          }
        })

        // loop through data + check if private or not
        // then update local state value of spaces with .some fn 
        // .some is used to prevent same value (space obj) being added > 1
        data.forEach((space) => {
          // find match of space stored locally to determine if it was originally set as private or not
          const localMatch = localData.filter(item => item.name === space)[0]
          // check private setting + assign as value
          const publicSetting = localMatch !== undefined ? localMatch.public : false 
          const spaceObj = self.createLocalSpace(space, publicSetting)
          self.spaces.some(x => x.name === spaceObj.name) ? false : self.spaces.push(spaceObj) 
        })
      })
    },
    handle_public_setting(data) {
      // for some reason delay / timeout is needed here
			const self = this 
			setTimeout(() => {
				self.spaces = this.spaces.map(obj => {
					if (obj.name === data.name) {
						return {...obj, public: true}
					}
					return {...obj}
				})
			}, 250)
    },
  },
  methods: {
    createLocalSpace(name, publicSetting) {
      return {
        name: name,
        public: publicSetting
      }
    },
    // this may not be needed here...
    formatNamespace(nsp) {
      return new Namespace(nsp).formatNsp()
    },
  },
};
</script>

<style lang="scss" scoped>

.dashboard {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.rooms--wrapper {
  margin-bottom: 2rem;
  & ul {
    list-style: none;
    li {
      & a {
        color: blue;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

.intro--wrapper {
  max-width: 50rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.intro--wrapper a {
  display: block;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

* > input {
  border: solid black 1px;
}
</style>