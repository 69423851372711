<template>
  <div class="space" @mousemove='handleMouseMove'>

    <Header :recordings='this.recordings' :socket='this.$socket' :namespace='this.namespace'/>
    <Question></Question>
    <UserCursor :key='user.id' v-for="user in this.users" :id='user.id' :x='returnPosX(user)' :y='returnPosY(user)' :socket='$socket' />
    <Recorder :namespace='this.namespace' :users='this.users' :isRecording='this.isRecording' :recordings='this.recordings' :socket='this.$socket' :mobile='false' />
    <Footer :userCount='this.users.length' />

    <!-- make into seperate component -->
    <Placeholder :key='placeholder.id' v-for="placeholder in this.placeholders" :id='placeholder.id' :x='returnPosX(placeholder)' :y='returnPosY(placeholder)' />

    <transition name="invite">
      <InviteModal v-if='this.invite_modal_open' :richText='this.inviteText' />
    </transition>

    <transition name="slide">
      <InfoModal v-if='info_modal_open' :richText='this.infoText' />
    </transition>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import types from "../../utils/types.js";
import regex from "../../utils/regex.js";
import { default as returnSignature } from "../../utils/signature.js";
import Namespace from "../../utils/namespace.js";
import Header from "../components/space/Header"
import Question from "../components/space/Question"
import Footer from "../components/space/Footer"
import Recorder from "../components/space/Recorder"
import Placeholder from "../components/space/Placeholder"
import UserCursor from "../components/space/UserCursor"
import InviteModal from "../components/global/InviteModal"
import InfoModal from "../components/global/InfoModal"
import Localbase from 'localbase'
import info from '../content/info.js'
import invite from '../content/invite.js'


// ...
let db = new Localbase('db')
db.config.debug = false


export default {
  name: "Space",
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      namespace: "",
      recordings: [],
      users: [],
      // socketId not currently in use
      socketId: '',
      isRecording: false,
      placeholders: [],
      userSignature: ''
    };
  },
  components: {
    Header,
    Question,
    Recorder,
    Placeholder,
    Footer,
    UserCursor,
    InviteModal,
    InfoModal
  },
  mounted() {
    // todo: fix this, using :key on router-view (Space component)
    // should have fixed this issue, however it still remains 
    const windowSlug = window.location.pathname.split('/').slice(1)[1]
    // this.$route.params.id
    if (regex.test(windowSlug)) {
      this.handleDbConnection()
    }
    else {
      const self = this
      const newRoomSlug = new Namespace(this.$route.params.id).slugifyNsp()
      // keep eye on this -- would be ideal to watch a property, or give the 
      this.$router.push({ path: "/spaces/" + newRoomSlug, params: { id: newRoomSlug}}).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }).then(() => {
        self.handleDbConnection()
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    // disconnect namespace
    this.$socket.emit("register_namespace_exit")
    next()
  },
  watch: {
    users(newVal, oldVal) {
      console.log('users!', newVal)
    },
    recordings(newVal, oldVal) {
      // once recordings are pushed to, sync the local store of this component to localdb 
      this.saveToStorage()
    },
    compose_filter(newVal, oldVal) {
      // when compose filter is updated 
      // update the recordings array + organize by new value 
      switch (newVal) {
        case 'timeAsc' :
          this.recordings = this.recordings.sort((a, b) => a.time - b.time)
          break 
        case 'timeDesc' :
          this.recordings = this.recordings.sort((a, b) => b.time - a.time)
          break 
        case 'lengthAsc' :
          this.recordings = this.recordings.sort((a, b) => b.duration - a.duration)
          break 
        case 'lengthDesc' :
          this.recordings = this.recordings.sort((a, b) => a.duration - b.duration)
          break 
        default :
          this.recordings = this.recordings.sort((a, b) => a.time - b.time)
          break 
      }
    },
  },
  computed: {
    ...mapState([
      'compose',
      'compose_filter',
      'invite_modal_open', 
      'info_modal_open'
    ]),
    infoText() {
      return info.richtext
    },
    inviteText() {
      return invite.richtext
    }
  },
  sockets: {
    // using component level managing of events for now
    // can switch to vuex if it gets too much
    connect: function () {
      this.$socket.emit("register_connection");
    },
    handle_connection_event: function (data) {
      const { users } = data
      this.users = users
      
      // assume private value   
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'public')) {
        // if was created with private value through form
        if (this.$route.params.public) {
          this.$socket.emit('register_public_setting')
        }
      }
      // else {
      //   console.log('now register private setting client side')
      //   this.$socket.emit('register_private_setting')
      // }
    },
    // handle_public_setting: function(data) {
      // console.log('handle public setting in client...')
    // },
    handle_disconnection_event: function (data) {
      // instead of overwriting, add/remove to array properly here
      this.users = data.users
      // 'user left' message or w.e.
    },
    handle_post_recording_event: function(data) {
      console.log('recording event?', data)
      this.recordings.push(data)
      this.removePlaceholder(data)
    },
    handle_update_recording_pos: function(data) {
      const self = this 
      this.recordings = this.recordings.map(obj => {
        if (obj.id === data.id) {
          return {...obj, pageX: data.x, pageY: data.y}
        }
        return obj
      })
    },
    handle_recording_start: function(data) {
      console.log('handle recording start', data)
      this.isRecording = true 
      this.addPlaceholder(data)
      // here need to set state in some way so that
      // we know where user is recording...
    },
    handle_recording_end: function(data) {
      console.log('handle recording end', data)
      this.isRecording = false 
      // console.log('handle recording end client side')
    },
    handle_cursor_move: function(data) { 
      this.users.map((obj) => {
        if (obj.id === data.id) {
          obj.x = data.x
          obj.y = data.y
        }
      })
    },
    handle_set_compose: function() {
      !this.compose ? this.setCompose() : false
    },
    handle_unset_compose: function() {
      this.compose ? this.unsetCompose() : false
    },
    handle_compose_filter: function(data) {
      this.setComposeFilter(data)
    },
    handle_max_nsp_error: function () {
      console.log("handle max nsp error client side");
      // ...return to dashboard here
    },
    handle_max_server_error: function () {
      console.log("handle max error server client side");
      // ...return to dashboard here
    },
  },
  methods: {
    ...mapActions([
      'setCompose', 
      'unsetCompose',
      'setComposeFilter'
    ]),
    addPlaceholder(obj) {
      this.placeholders.includes(obj) ? false : this.placeholders.push(obj)
    },
    removePlaceholder(obj) {
      // may want to use operators here instead
      const index = this.placeholders.findIndex(item => item.id === obj.id)
      this.placeholders.splice(index, 1)
    },
    handleDbConnection: function() {
      const self = this 
      // use URL slug for namespace
      const nsp = new Namespace(self.$route.params.id).slugifyNsp();
      self.namespace = nsp
      // when enter, when if the localbase has a collection for the namespace (url slug)
      db.collection(nsp).get().then(data => {
        if (data.length) {
          // self.recordings = []
          // as socket is in use, data will always be duplicated here
          // this is not really ideal way of handling data stores (component + localdb)
          // better way would be to store each entry into both with specific id (not time)
          // then check if it exists in array already, if not push to it
          // ...
          // may also be best not to overwrite, but to loop + push instead here
          self.recordings = data
        }
      })
    },
    handleMouseMove: function(event) {
      let pageX = event.pageX
      let pageY = event.pageY
      this.$socket.emit('register_cursor_move', { x: pageX, y: pageY })
    },
    // todo: calculations based on screen dimensions
    returnPosX: function(obj) {
      return obj.x 
    },
    returnPosY: function(obj) {
      return obj.y
    },
    saveToStorage: function() {
      // not ideal, but seems to work: empty db collection first to prevent duplicates
      db.collection(this.namespace).set([])
      
      const self = this
      // timeout seems to work better than $nextTick
      setTimeout(() => {
        // db.collection(self.namespace).set(self.recordings, { keys: true })
        if (db.collection(self.namespace) !== undefined) {
          db.collection(self.namespace).set(self.recordings)
        }
      }, 250)
    },
    handleClick() {
      this.$socket.emit("register_click");
    },
    handleRecord() {
    },
  },
};
</script>

<style scoped>
.record--btn {
  color: red;
  cursor: pointer;
}

/* transitions for modals */
.slide-enter-active {
  transition: all .3s ease;
}
.slide-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-leave-to {
  transform: translateY(0) translateX(0);
}


</style>