<template>
  <div :class="returnRecorderClass" @dblclick='handleDoubleClick'>    
    <ComposeFilter v-if='this.compose' :socket='this.socket' />
    <Audio v-for="recording in this.recordingsData" :signature='returnUserSignature(recording)' :key="returnAudioKey(recording.time)" :id='returnAudioId(recording)' :data="recording" :socket='socket' />
    <Message :condition='!this.showInstructions && !this.compose' :content='this.instructionsMessage' />
  </div>
</template>

<script>
import Audio from './Audio'
import ComposeFilter from './ComposeFilter'
import Message from './Message'
import types from "../../../utils/types.js";
import Namespace from "../../../utils/namespace.js";
import moment from "moment";
import { mapState, mapActions } from 'vuex'

export default {
  name: "Recorder",
  // will have data passed from socket store here
  props: {
    mobile: Boolean,
    socket: Object,
    users: Array,
    recordings: Array,
    namespace: String,
    isRecording: Boolean,
  },
  data() {
    return {
      // recorder which gets initiated
      recorder: false,
      gumStream: false,
      timer: null,
      currentRecordingTime: 0,
      maxRecordingTime: 1000,
      recordingsData: null,
      // ...added
      showInstructions: false,
      instructionsMessage: 'Double click anywhere to start recording',
    };
  },
  components: {
    Audio,
    ComposeFilter,
    Message
  },
  watch: {
    recordings: {
      deep: true,
      handler(newVal, oldVal) {
        this.recordingsData = newVal        
        const self = this 
        this.$nextTick(() => {
          self.$forceUpdate()
        })
      }
    },
    compose(newVal, oldVal) {
      newVal ? this.disableRecording() : this.enableRecording()
    },
    isRecording(newVal, oldVal) {
      const self = this 
      if (newVal) {
        this.instructionsMessage = 'Double click anywhere to stop recording'
      }
      else if (!newVal) {
        this.instructionsMessage = 'Drag the recording to anywhere on the screen'
        setTimeout(() => {
          self.instructionsMessage = 'Double click anywhere to start recording'
        }, 5000)
      }
    }
    // recording_enabled(newVal, oldVal) {
      // newVal ? this.showInstructions = true : this.showInstructions ? this.showInstructions = false : false
    // }
  },
  mounted() {
    const self = this;
    this.recordingsData = this.recordings
  },
  computed: {
     ...mapState(['compose', 'compose_filter', 'recording_enabled']),
    returnRecorderClass: function() {
      return this.compose ? 'recorder--wrap composed' : 'recorder--wrap uncomposed'
    },
    returnRecordingClass: function () {
      return this.isRecording ? "record--btn live" : "record--btn";
    },
  },
  methods: {
    ...mapActions([
      'enableRecording',
      'disableRecording',
    ]),
    // to get this to work will need to return a $socket id 
    // from the audio object so it can be matched...
    returnUserSignature(obj) {
      const user = this.users.filter(user => user.id === obj.socketId)[0]
      return user ? user.signature : '?'
    },
    handleDoubleClick(event) {
      if (this.recording_enabled) {
        this.showInstructions ? this.showInstructions = false : false
        let pageX = event.pageX
        let pageY = event.pageY
        this.handleRecord(pageX, pageY)
      }
    },
    returnAudioKey(time) {
      return this.socket.id + time 
    },
    returnAudioId(obj) {
      // id is already passed to + back from server (using blob:url)
      // so no need to do any computation/adding here as no longer using $socket id
      const audioId = obj.id
      return audioId
    },
    returnBlob(data) {
      let blob = new Blob([data.audio], { type: "audio/mpeg; codecs=opus" });
      let audioUrl = window.URL.createObjectURL(blob);
      let currentTime = moment(data.time).format("MM/DD/YYYY hh:mm a");
      return `<div class='audio--wrapper'><audio src=${audioUrl} controls></audio><div><span>${currentTime}</span><span>&nbsp;</span><span>${data.duration} Seconds</span></div></div>`;
    },
    trackRecordingTime() {
      this.currentRecordingTime += 1 
      if (this.currentRecordingTime >= this.maxRecordingTime) {
        clearInterval(this.timer)
        this.currentRecordingTime = 0 
        this.handleStopRecording()
      }
    },
    handleStopRecording() {  
      // this should only be cleared if not already cleared...
      clearInterval(this.timer)
      // this.disableRecording()
      this.recorder.stop();
      this.gumStream.getAudioTracks()[0].stop();
      this.socket.emit("register_recording_end");
    },
    handleRecord(x, y) {
      const self = this;
      let startTime, endTime, duration;
      // if recorder is initalized & already recording then stop() it
      if (self.recorder && self.recorder.state == "recording") {
        self.handleStopRecording()
        // self.disableRecording()
      } else {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(function (stream) {
            self.gumStream = stream;
            // initialize recorder
            self.recorder = new MediaRecorder(stream);
            self.recorder.onstart = function () {
              // limit recording here after x amount of time
              self.timer = setInterval(self.trackRecordingTime, 10)
              this.chunks = [];
              // save time here for recording length
              startTime = moment();
              console.log('stream?', stream)
              // pass stream id for unique identifier
              // so when it is returned from the server, we can select the correct placeholder to remove 
              self.socket.emit("register_recording_start", { pageX: x, pageY: y, id: stream.id });
            };
            self.recorder.ondataavailable = function (e) {
              this.chunks.push(e.data);
            };
            // callback for when recording finishes
            self.recorder.onstop = function (e) {

              // save time here for recording length
              endTime = moment();

              // todo: format duration here as hh:mm:ss
              duration = moment.duration(endTime.diff(startTime)).seconds();

              var blob = new Blob(this.chunks, {
                type: "audio/mpeg; codecs=opus",
              });
              var audioUrl = window.URL.createObjectURL(blob);
              
              var d = new Date();
              var rercordedTime = d.getTime()
              
              // an id is necessary that can be unique, but not depend upon socket
              // for now just using the blob url + the time as a string (this should be unique enough)
              // app needs to share ids as a component property across sockets
              // but these ids must be unique (to ensure the correct audio out of many is updating)
              // but not depend upon socket.id, as this would only update for the user with same socket.id
              // var recordingId = audioUrl + rercordedTime
              // we could also use stream id here (+ keep consistent with where ids are being passed elswehre)
              
              // keep eye on this -- now using it instead of audio url + timestamp concatenated
              const recordingId = stream.id

              var recordingObj = {
                audio: blob,
                time: rercordedTime,
                duration: duration,
                // pass namespace to object
                namespace: self.namespace,
                pageX: x,
                pageY: y,
                // added
                id: recordingId,
                _key: recordingId
              };
              // ...
              // if (socketConnected) {
              self.socket.emit("save_recording", recordingObj);
              // }
            };
            // start recorder
            self.recorder.start();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recording--live {
  top: 0;
  position: absolute;
  background: red;
  color: white;
  display: inline-flex;
  padding: 1rem;
  width: auto;
  border-radius: 2rem 2rem;
  margin: 6.5rem 0 0 0.5rem;
  animation: flash 0.75s linear infinite;
}

// temp
.recorder--wrap {
  &.composed {
    display: flex;
    // flex-flow: row wrap;
    flex-flow: column;
    flex: 0 1 auto;
    padding: 1rem;
    justify-content: center;
  }
  &.uncomposed {
    display: flex;
    flex: 1;
    flex-flow: column;
  }
  // height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // need some kind of cursor...
  cursor: cell;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>