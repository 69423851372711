<template>
  <div class="compose--filter">
    <div :class='returnItemClass("timeAsc")' @click='handleClick("timeAsc")'>Time ↑</div>
		<div :class='returnItemClass("timeDesc")' @click='handleClick("timeDesc")'>Time ↓</div>
		<div :class='returnItemClass("lengthAsc")' @click='handleClick("lengthAsc")'>Length ↑</div>
		<div :class='returnItemClass("lengthDesc")' @click='handleClick("lengthDesc")'>Length ↓</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "ComposeFilter",
  props: {
    socket: Object
  },
  mounted() {
    console.log("mount compose filter")
  },
  computed: {
    ...mapState(['compose_filter', 'full_audio_playback'])
  },
	methods: {
		returnItemClass(str) {
      const pointerClass = this.full_audio_playback ? 'disabled' : false 
			return this.compose_filter === str ? 
        'compose--filter__item button--bubble active' + ' ' + pointerClass 
        : 
        'compose--filter__item button--bubble' + ' ' + pointerClass
		},
		handleClick(str) {
      !this.full_audio_playback ? this.socket.emit('register_compose_filter', str) : false 
		},
	}
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.compose--filter {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	font-size: 50%;
	margin-bottom: 1rem;
	&__item {
		background: white;
		font-size: 1.4rem !important;
		margin-right: .5rem;
    &.disabled {
      cursor: not-allowed;
      &:hover {
        background: white;
        color: black;
      }
    }
	}
}

</style>