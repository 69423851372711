import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    mobile: false,
    // recording_enabled: false,
    recording_enabled: true, 
    audio_blob_playing: null,
    audio_playback: false,
    full_audio_playback: false,
    compose: false,
    // should always sync with socket
    compose_filter: 'timeAsc',
    volume: .5,
    invite_modal_open: false,
    info_modal_open: false
  },
  mutations,
  actions,
  getters
});

export default store