<template>
  <form class="form--wrapper" @submit='createRoom'>
    <!-- todo: proper semantic markup for form -->
    <!-- <div class = "form--wrapper__checkbox">
	    <label for="checkbox">Public Session?</label>
		  <input v-model="newRoomPublic" type="checkbox" id="checkbox" />
    </div> -->
    <input v-model="newRoom" type="text" id="newRoom" placeholder="Enter session name" />
    <!-- <button @click="createRoom">Submit</button> -->
  </form>
</template>

<script>
import Namespace from "../../../utils/namespace.js";

export default {
  name: "DashboardForm",
  data() {
    return {
      // state for v-model (input)
      newRoom: "",
	    newRoomPublic: false
    }
  },
  methods: {
    createRoom() {
	    const self = this
      const newRoomSlug = new Namespace(this.newRoom).slugifyNsp()
      this.$router.push({name: 'Space', params: { public: self.newRoomPublic, id: newRoomSlug }})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_global.scss";

.form--wrapper {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  display: flex;
  flex-flow: column;
  &__checkbox {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    & label {
      margin: 0 .5rem 0 0;
    }
  }
  & input {
    text-align: center;
    font-size: 1.4rem;
    background: white;
    padding: 0.8rem 1rem 0.6rem;
    border-radius: 2rem 2rem;
  }
}

</style>